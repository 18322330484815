import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import ReactLoading from 'react-loading';

import { FaTelegramPlane, FaPlus, FaMinus, FaRegDizzy, FaTimes } from "react-icons/fa";
import { BsChevronUp } from 'react-icons/bs';
import { AiFillUpCircle, AiOutlineUp } from 'react-icons/ai';


import FAQ from '../../component/fap/fap';
import Layout from "../../component/layout/layout";
import "./home.scss";
import { ToHTML, scrollTo } from '../../util';
import firstSectionBackground from "../../assets/logo.svg"

const Home = () => {

	const [delayTime, setDelayTime] = useState(true);
	const [display, setDisplay] = useState(true);
	useEffect(() => {
		setTimeout(() => {
			setDelayTime(false)
		}, 6000)
		setTimeout(() => {
			setDisplay(false)
		}, 3000)
	}, [])

	const [chainlogos, setChainlogos] = useState([
		{
			name: 'BNB smart <br/> chain',
			img: 'binance.svg'
		},
		{
			name: 'Ethereum',
			img: 'ethereum.svg'
		},
		{
			name: 'Polygon',
			img: 'polygon.svg'
		},
		{
			name: 'Fantom',
			img: 'fantom.svg'
		},
		{
			name: 'Avalanche',
			img: 'avalanche.svg'
		},
		{
			name: 'Cronos',
			img: 'cronos.svg'
		},
		{
			name: 'Arbitrum',
			img: 'arbitrum.svg'
		},
		{
			name: 'Aurora',
			img: 'aurora.svg'
		},
		{
			name: 'Klaytn',
			img: 'klaytn.svg'
		},
		{
			name: 'Solana',
			img: 'solana.svg'
		},
		{
			name: 'Optimism',
			img: 'optimism.svg'
		},
	])

	const [flashTech, setFlashTech] = useState([
		{
			title: 'Flash Transfer <br/> Exchange',
			img: 'flash-transfer.png',
			content: 'Buy, sell, transfer, directly from <span className="primary- color">Flash Transfer</span> partner <span className="primary-color"> merchants</span> with <span className="primary-color">Flash Wallet</span> !'
		},
		{
			title: 'Dead Wallet - <span className="primary-color">Decentralized</span> <br/> Write Testament for your heiress',
			img: 'dead-wallet.png',
			content: 'Define your <span className="primary-color">testament</span> the Cryptos  & NFT to send, the time thanks to the <span className="primary-color"> gas fee insurance </span> even if you have no gas fees when the cryptos are going to be sent, security is added because our contract will send you gas fees . You can cancel the signing of the contract at any time !'
		},
		{
			title: 'Flash Recharge',
			img: 'recharge.png',
			content: 'Top up your non-custodial <span className="primary-color"> Flash Wallet</span>, world exclusive, directly with a prepaid card available from our partner merchants or on the e-shop !'
		},
		{
			title: 'Flash Virtual Card',
			img: 'virtual-card.png',
			content: 'Spend your crypto directly on virtual card in Flash wallet ! <br/> You can pay directly with <span className="primary-color"> Google Wallet</span> or <span className="primary-color">Apple Pay</span>'
		},
		{
			title: 'Flash Swap',
			img: 'swap.png',
			content: 'Use our world`s most secure direct built-in swap !'
		},
		{
			title: 'Flash Wallet Multi-Chain',
			img: 'multi-chain.png',
			content: 'Get a complete view of your active cryptos (Tokens and NFTs) on your wallet.'
		},
		{
			title: 'Flash Staking',
			img: 'staking.png',
			content: 'Access attractive rates, without ponzi earn for our ecosystem'
		},
		{
			title: 'Flash Bridge',
			img: 'bridge.png',
			content: 'Bridge tokens from one chain to another quickly, easily and cheaply'
		},
		{
			title: 'Flash VPN',
			img: 'vpn.png',
			content: 'Protect your privacy with a built-in <span className="primary-color">VPS</span> directly in Flash Wallet'
		},
		{
			title: 'Flash Browser',
			img: 'browser.png',
			content: 'Browse safely and interact with any Dapps in our browser. <br/> Earn Flash using your desktop browser in a future version'
		},
		{
			title: 'Flash File Storage',
			img: 'storage.png',
			content: 'Store your data on a <span className="primary-color">decentralized</span> cloud via the <span className="primary-color">blockchain</span> which guarantees the best security, anonymity and transparency'
		},
		{
			title: 'Flash NFT Marketplace',
			img: 'marketplace.png',
			content: 'Find all the popular marketplaces on a single marketplace like Opensea, Rarible ...'
		},
	])

	const [partners, setPartners] = useState([
		{
			img: 'binance.png'
		},
		{
			img: 'trustpilot.png'
		},
		{
			img: 'synaps.png'
		},
		{
			img: 'binance-chain-logo-vector.svg'
		},
		{
			img: 'ledger.png'
		},
		{
			img: 'google-wallet.png'
		},
		{
			img: 'fireblock.png'
		},
		{
			img: 'uniswap.png'
		},
		{
			img: 'pancake.png'
		},
		{
			img: 'multichain.png'
		},
		{
			img: 'moonpay.png'
		},
		{
			img: 'apple-pay.png'
		},
	])

	const topScroll = (id: any) => {
		// window.scrollTo(0, 0);
		scrollTo({ id });
	}


	return (
		<>
			{
				display ? <>
					<div className={`isloading ${!delayTime ? "dis-none" : ""}`}>
						<div className='loading-img justify'>
							<img src='/logo2.png' width={'70px'} className='' alt='' />
							<span style={{ fontSize: '30px', fontWeight: 'bold', color: '#e7c766' }}>FLASH</span>
						</div>
					</div>
					{/* <div className={`delay-time ${delayTime ? 'show-loading' : ''}`}>
						<ReactLoading className="react-loading-style" delay={0} type={'spin'} color={'#EFB219'} height={150} width={200} />
					</div> */}
				</> : <>
					{/* <div className={`delay-time ${delayTime ? 'show-loading' : ''}`}>
						<ReactLoading className="react-loading-style" delay={0} type={'spin'} color={'#EFB219'} height={150} width={200} />
					</div> */}
					<div className={`isloading ${!delayTime ? "dis-none" : ""}`}>
						<div className='loading-img justify'>
							<img src='/logo2.png' width={'70px'} className='' alt='' />
							<span style={{ fontSize: '30px', fontWeight: 'bold', color: '#e7c766' }}>FLASH</span>
						</div>
					</div>
					<Layout>


						< section className="first-section " id="first-section" >
							{/* < section className="first-section " style={{ backgroundImage: `url(/logo.svg)` }} id="first-section" > */}
							<div className="container overflow-hidden">
								<div className="flex flex-col text-center sm:text-left">
									<div className="row items-center justify-center">
										<div className=" pt-36">
											<h1 className="font-bold">
												<div className="">
													Flash <span className="primary-color">Wallet</span>, Unique Defi Service <span className="primary-color">#1</span>
												</div>
											</h1>
										</div>
										<div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-9 items-center
						 justify-center">
											<div className="">
												<h3 className="">We <span className="primary-color">simplify</span> & <span className="primary-color">convert  easily</span></h3>
												<br />
												<h3 className="">Defi unique services for you <span className=""> independence</span></h3>
												<br />
												<h3 className="">Not your <span className="primary-color">key</span> not your
													<span className="primary-color"> crypto</span> !</h3>
												<br />
												<div className="flex items-center justify-center sm:justify-start gap-4 sm:gap-8 mt-8 flex-col sm:flex-row">
													<button className="btn primary-bg gap-2 text-black cursor-pointer hover:scale-110 font-bold" style={{ borderRadius: '16px', padding: '18px 39px' }}>
														<span>
															Launch App
														</span>
													</button>
													<img src="/assets/images/landing/buttons/google-pay.svg" alt="" className="cursor-pointer hover:scale-110" />
													<img src="/assets/images/landing/buttons/apple.svg" alt="" className="cursor-pointer hover:scale-110" />
												</div>
												<div className="flex flex-wrap items-center justify-center sm:justify-start sm:ml-16 gap-8 mt-8">
													<img src="/assets/images/landing/lock.png" alt="" className="cursor-pointer hover:scale-110 mb-5" />
													<img src="/assets/images/landing/fireblock.png" alt="" className="cursor-pointer hover:scale-110" />
													<img src="/assets/images/landing/ledger.png" alt="" className="cursor-pointer hover:scale-110 mt-4" />
												</div>

											</div>
										</div>
										<div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-9">
											<img className="home-img" src="/assets/images/landing/introduction.svg" alt="" />
										</div>
									</div>
								</div>
							</div>
						</section >

						<section className="simple-section pt-4 ">
							<div className="container mx-auto flex flex-col pt-8 pb-6 sm:pt-12 sm:pb-5">
								<div className="row">
									<div className="col-6 col-md-3 mb-8 text-center">
										<div className="">
											<p className="">Total Volume</p>
											<h4 className="primary-color">
												$1M+
											</h4>
										</div>
									</div>
									<div className="col-6 col-md-3 mb-8 text-center">
										<div className="">
											<p className="">Wallet Connected</p>
											<h4 className="primary-color">
												10K+
											</h4>
										</div>
									</div>
									<div className="col-6 col-md-3 mb-8 text-center">
										<div className="">
											<p className="">Total Transactions</p>
											<h4 className="primary-color">
												100k+
											</h4>
										</div>
									</div>
									<div className="col-6 col-md-3 text-center">
										<div className="">
											<p className="">Supported Chains</p>
											<h4 className="primary-color">
												EVM
											</h4>
										</div>
									</div>
								</div>
								{/* <AiFillUpCircle className="up-icon" /> */}

							</div>
						</section>

						<div className="up-icon hover:scale-110">
							<h5 className="">
								<AiOutlineUp onClick={() => topScroll('first-section')} className=" mb-[1px]  cursor-pointer" />
							</h5>
						</div>

						<section className="dead-wallet-section pt-4 " id="dead_wallet">
							<div className="container mx-auto flex flex-col py-8 sm:py-16">
								<div className="w-full flex justify-center flex-col text-center">
									<h3 className="">Dead Wallet</h3>
									<div className="small-underline m-auto mt-2"></div>
								</div>
								<div className="mt-20"></div>
								<div className="row">
									<div className="col-sm-12 col-md-6 col-lg-6 mb-8">
										<div className=" flex justify-start items-center">
											<h1>Flash <span className="primary-color">Wallet</span>, dead function</h1>
											<div className=" ml-3"><FaRegDizzy className=" text-3xl" /></div>
										</div>
										<br />
										<p className="">
											First <span className="primary-color">decentralized function</span> in the world that allows you to send your crypto assets in the event of <span className="primary-color">death </span> !
										</p>
										<br />

										<p className=" text-xs font75 leading-7">
											Define the cryptos to send, the time thanks to the <span className="primary-color">gas fee insurance</span> even if you have no gas fees when the cryptos are going to be sent, security is added because our contract will <span className="primary-color">send you gas fees</span>. You can cancel the signing of the contract at any time ! You can set an address of an EVM compatible CEX (0x....) if your tokens are listed there we recommend the most popular ones but the best is <span className="primary-color">wallet non-custodial</span> address !
										</p>
										<div className="mt-4 flex justify-center sm:justify-start items-center gap-2 sm:gap-10">
											<button className="btn primary-bg text-black font-bold" style={{ borderRadius: '16px' }}>
												Download Flash Wallet
											</button>
											<button className="btn primary-bg text-black font-bold">
												Watch Video
											</button>
										</div>
										<div className="flex  flex-col sm:flex-row justify-center sm:justify-start items-center mt-8">
											<div className=" max-w-[230px] w-full gap-4 flex justify-center flex-col text-center both-border">
												<p className="">Wallet Users Crypto</p>
												<h3 className="primary-color">
													30M+
												</h3>
											</div>
											<div className=" max-w-[230px] w-full gap-4 flex justify-center flex-col text-center right-border">
												<p className="">Total Volume Lost</p>
												<h3 className="primary-color">
													$10B+
												</h3>
											</div>
											<div className=" max-w-[230px] w-full gap-4 flex justify-center flex-col text-center right-border">
												<p className="">Number of potential Users</p>
												<h3 className="primary-color">
													1B+
												</h3>
											</div>
										</div>
									</div>
									<div className="col-sm-12 col-md-6 col-lg-6 mb-8">
										<div className="flex justify-center sm:justify-end items-center w-full h-full ">
											{/* <img className="" src="/assets/images/landing/bnb-ceo.png" alt="" /> */}
											<iframe style={{ maxWidth: '510px', width: '100%' }} className=" h-[300px] lg:h-[100%]" src="https://www.youtube.com/embed/_QEIvJ8aqMM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
										</div>
									</div>
								</div>
							</div>
						</section>

						<section className="spend-section" id="flash_recharge">
							<div className="container mx-auto flex flex-col py-8 sm:py-16">
								<div className="w-full flex justify-center flex-col text-center">
									<h3 className="">Top up & spend</h3>
									<div className="small-underline m-auto mt-2"></div>
								</div>
								<div className="mt-12"></div>
								<div className="row">
									<div className="col-sm-12 col-md-6 mb-8 overflow-x-hidden">
										<div className=" flex justify-center sm:justify-start">
											<div className=" relative">
												<img src="/assets/images/landing/2card.png" alt="" className="" />
												{/* <img src="/assets/images/landing/card.png" alt="" className="flash-recharge-card2" /> */}
											</div>
										</div>
										<div className=" flex justify-center sm:justify-end mt-20">
											<img src="/assets/images/landing/visa.png" alt="" className="" />
										</div>
									</div>
									<div className="col-sm-12 col-md-6 mb-8  flex flex-col items-start justify-center">
										<h5 className="leading-7">
											Recharge your account with our prepaid <span className="primary-color">Flash recharge</span> cards & spend your crypto assets <br /> with your integrated <span className="primary-color">virtual Flash card</span> !
										</h5>
										<br />
										<br />
										<p className="font75 text-sm leading-5">
											Top up your non-custodial <span className="primary-color">Flash Wallet</span>, world exclusive, <br /> directly with a prepaid card available from our partner <br /> merchants or on the e-shop ! <br />
											This avoids going through an exchange and provides access <br /> to crypto in many countries that <span className="primary-color"> only use cash</span> !
										</p>
										<div className="flex flex-col sm:flex-row justify-center sm:justify-start items-center gap-4 mt-16 flex-wrap">
											<img src="/assets/images/landing/buttons/google-pay-black.svg" alt="" className="cursor-pointer hover:scale-110" />
											<img src="/assets/images/landing/buttons/apple-black.svg" alt="" className="cursor-pointer hover:scale-110" />
											<img src="/assets/images/landing/buttons/apk-black.svg" alt="" className="cursor-pointer hover:scale-110" />
										</div>
									</div>
								</div>
							</div>
						</section>

						<section className="multi-chain-section" id="integrate_vpn">
							<div className="container mx-auto flex flex-col py-8 sm:py-16">
								<div className="w-full flex justify-center flex-col text-center">
									<h3 className="">The only Multi-Chain <span className="primary-color">Flash wallet</span> you need for be <span className="primary-color"> free</span></h3>
									<div className="small-underline m-auto mt-6"></div>
									<p className="mt-6">Supported all EVM Chains and more</p>
								</div>
								<div className="mt-12"></div>
								<div className="w-full flex flex-wrap justify-center text-center gap-8">
									{
										chainlogos.map((item, index) => (
											<div className=" text-center hover:scale-110" key={index}>
												<img src={"/assets/images/landing/multichain/" + item.img} alt="" className={`w-[45px] h-[45px] rounded-full m-auto cursor-pointer`} />
												<p className="">{ToHTML(item.name)}</p>
											</div>
										))
									}
								</div>
								<br />
								<br />
								<div className="multi-chain-card-container">
									{
										flashTech.map((item, index) => (
											<div className="multi-chain-card" key={index}>
												<p className="">{ToHTML(item.title)}</p>
												<img src={"/assets/images/landing/multichain/func/" + item.img} alt="" className={``} />
												<span className="">
													{ToHTML(item.content)}
												</span>

												<div className="multi-chain-card-top"></div>
												<div className="multi-chain-card-bottom"></div>
											</div>
										))
									}
								</div>

							</div>
						</section>

						<section className="partners-section" id="flash_transfer">
							<div className="container mx-auto flex flex-col py-8 sm:py-16">
								<div className="w-full flex justify-center flex-col text-center">
									<h3 className="">Flash Merchants partners </h3>
									<div className="small-underline m-auto mt-6"></div>
								</div>
								<div className="mt-12"></div>
								<div className="flex flex-col sm:flex-row justify-start items-center gap-10">
									<img src="/assets/images/landing/flash-wallet.svg" alt="" className="max-w-[300px] w-[100%]" />
									<FaTimes className=" text-2xl" />
									<img src="/assets/images/landing/flash-transfer.svg" alt="" className="max-w-[300px] w-[100%]" />
								</div>
								<div className="mt-20"></div>
								<div className="row">
									<div className="col-sm-12 col-md-6">
										<h4 className="">
											Use <span className="primary-color"> Flash Transfer</span> directly on your <span className=""> Flash Wallet </span>!
										</h4>
										<br />
										<br />
										<p className="">Buy, sell, transfer, your crypto assets directly from <span className="primary-color"> Flash Transfer</span> partner merchants with <span className=""> Flash Wallet</span>!</p>
										<br />
										<button className="btn primary-bg text-black font-bold" style={{ borderRadius: '16px' }}>Join Flash Revolution</button>

										<div className="flex  flex-col sm:flex-row justify-center sm:justify-start items-center mt-8">
											<div className=" max-w-[230px] w-full gap-4 flex justify-center flex-col text-center both-border">
												<p className="">Number of Merchants</p>
												<h3 className="primary-color">
													20 000+
												</h3>
											</div>
											<div className=" max-w-[230px] w-full gap-4 flex justify-center flex-col text-center right-border">
												<p className="">Total Volume Raised</p>
												<h3 className="primary-color">
													$1M+
												</h3>
											</div>
											<div className=" max-w-[230px] w-full gap-4 flex justify-center flex-col text-center right-border">
												<p className="">Number of Participants</p>
												<h3 className="primary-color">
													10 000+
												</h3>
											</div>
										</div>

									</div>
									<div className="col-sm-12 col-md-6 relative">
										<img src="/assets/images/landing/partners/defi-pc.png" alt="" className="partners-img" />
									</div>

								</div>
							</div>
						</section>

						<section className="mobile-section" id="download">
							<div className="container mx-auto flex flex-col py-8 sm:py-16">
								<div className="w-full flex justify-center flex-col text-center">
									<h3 className="">Mobile App </h3>
									<div className="small-underline m-auto mt-6"></div>
								</div>
								<div className=" mt-6 sm:mt-12"></div>
								<div className="row">
									<div className="col-sm-12 col-md-6 mb-3 flex">
										<img src="/assets/images/landing/mobile/phone.png" alt="" className="partners-img mt-0 lg:-mt-28 ml-16 sm:ml-0" />
									</div>
									<div className="col-sm-12 col-md-6 mb-3 relative">
										<div className="flex w-full mb-20">
											<img src="/assets/images/landing/flash-wallet.svg" alt="" className="m-auto lg:ml-[15%] max-w-[250px]" />
										</div>
										<h4 className="">
											The revolution of DEFI wallets before your eyes !
										</h4>
										<br /><br />
										<span className=" text-xs opacity-75">
											Download the Flash Wallet app to access the DeFi with the best services
										</span>
										<br /><br />

										<div className="flex flex-col md:flex-row flex-wrap justify-start items-start sm:items-center gap-6 mt-8">
											<img src="/assets/images/landing/buttons/google-pay.svg" alt="" className="w-[140px] lg:w-[130px] cursor-pointer hover:scale-110" />
											<img src="/assets/images/landing/buttons/apple.svg" alt="" className="w-[140px] lg:w-[130px] cursor-pointer hover:scale-110" />
											<img src="/assets/images/landing/buttons/apk.svg" alt="" className="w-[140px] lg:w-[130px] cursor-pointer hover:scale-110" />
										</div>
										{/* <br />
							<div className="w-full">
							</div> */}
									</div>

								</div>
							</div>
						</section>

						<section className="follow-section">
							<div className="container mx-auto flex flex-col py-8 sm:py-12">
								<div className="w-full flex justify-center flex-col text-center">
									<h3 className="">Follow Us </h3>
									<div className="small-underline m-auto mt-6"></div>
								</div>
								<div className="mt-16"></div>
								<div className="flex justify-center sm:justify-start items-center gap-6 m-auto flex-wrap">
									<img src="/assets/images/landing/social/twitter.png" alt="" className="cursor-pointer hover:scale-125" />
									<img src="/assets/images/landing/social/telegram.png" alt="" className="cursor-pointer hover:scale-125" />
									<img src="/assets/images/landing/social/youtube.png" alt="" className="cursor-pointer hover:scale-125" />
									<img src="/assets/images/landing/social/facebook.png" alt="" className="cursor-pointer hover:scale-125" />
									<img src="/assets/images/landing/social/linkedin.png" alt="" className="cursor-pointer hover:scale-125" />
									<img src="/assets/images/landing/social/tiktok.png" alt="" className="cursor-pointer hover:scale-125" />
									<img src="/assets/images/landing/social/instagram.png" alt="" className="cursor-pointer hover:scale-125" />
								</div>
							</div>
						</section>

						<section className="trustpilot-section" id="here_partners">
							<div className="container mx-auto flex flex-col py-8 sm:py-16 z-10 relative">
								<>
									<div className="w-full flex justify-center flex-col text-center">
										<h3 className="">Our Core Team has worked</h3>
										<div className="small-underline m-auto mt-6"></div>
									</div>
									<div className="flex flex-wrap justify-center items-center gap-8 w-full">
										<img src="/assets/images/landing/core-team/huawei.png" alt="" className="cursor-pointer hover:scale-125" />
										<img src="/assets/images/landing/core-team/alienware.png" alt="" className=" mb-0 sm:mb-11 cursor-pointer hover:scale-125" />
										<img src="/assets/images/landing/core-team/aliexpress.png" alt="" className="cursor-pointer hover:scale-125" />
										<img src="/assets/images/landing/core-team/hp.png" alt="" className="cursor-pointer hover:scale-125" />
										<img src="/assets/images/landing/core-team/ovhcloud.png" alt="" className="cursor-pointer hover:scale-125" />
										<img src="/assets/images/landing/core-team/canal.png" alt="" className="cursor-pointer hover:scale-125" />
										<img src="/assets/images/landing/core-team/hermes-paris.png" alt="" className="cursor-pointer hover:scale-125" />
									</div>
								</>

								<div className=" p-10 sm:p-20"></div>

								<>
									<div className="w-full flex justify-center flex-col text-center" >
										<h3 className="">Partners</h3>
										<div className="small-underline m-auto mt-6"></div>
									</div>
									<br />
									<br />
									<br />
									<div className="partners-card-container">
										{
											partners.map((item, index) => (
												<div className="partners-card" key={index}>
													<img src={"/assets/images/landing/site-partners/" + item.img} alt={`img`} className={``} />

													<div className="partners-card-top"></div>
													<div className="partners-card-bottom"></div>
												</div>
											))
										}
									</div>

								</>

								<>
									<div className="mt-52"></div>
									<div className="flex gap-4 m-auto">
										<img src="/assets/images/landing/site-partners/trustpilot.png" alt="" className="" />
									</div>
									<div className="mt-10 text-center">
										<div className="max-w-3xl w-full m-auto">
											<h4 className="">
												“Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo expedita voluptas culpa sapiente alias molestiae. Numquam corrupti in laborum sed rerum et corporis.”
											</h4>
										</div>
										<div className="mt-12 "></div>
										<div className="flex items-center justify-center gap-3 m-auto">
											<img src="/assets/images/testimonial/avatar.png" alt="" className="" />
											<p className=" text-xl">
												Amita
											</p>
											<img src="/assets/images/testimonial/path.png" style={{ height: '20px' }} alt="" className="" />
											<p className="text-xl grey-color">
												CEO, Workcation
											</p>
										</div>
									</div>
								</>
							</div>
							<img src="/assets/images/testimonial/pattern.png" alt="" className="pattern" />

						</section>


					</Layout>
				</>
			}
		</>
	);
};
export default Home;
