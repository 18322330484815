
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { simpleAddress, scrollTo } from "../../util";
import { AiOutlineMinus, AiOutlineClose } from "react-icons/ai";
import { FaTwitter, FaTelegramPlane, FaInstagram, FaFacebookF, FaGithub } from "react-icons/fa";
import { FlashLogoSvg } from '../../assets/svg/svg';
import "./header.scss";
import RequestModal from '../RequestModal';

const Header = () => {
	const [requestModal, setRequestModal] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const topScroll = (id: any) => {
		// window.scrollTo(0, 0);
		window.innerWidth < 1200 ? setIsMobile(false) : scrollTo({ id });
		scrollTo({ id });
	}
	return (
		<header className=' header_'>
			<div className='container flex header-container'>
				<div className='flex items-center justify-between mobile-just-start flex4 row-reverse-991'>
					<div className='flex items-center justify-between w-full lg:w-0 flex-row-reverse sm:flex-row'>
						<div onClick={() => setIsMobile(true)} className='hamburger-icon-container'>
							<AiOutlineMinus className='' style={{ position: 'absolute', top: '0%' }} fontSize={'25px'} />
							<AiOutlineMinus className='' style={{ position: 'absolute', top: '20%' }} fontSize={'25px'} />
							<AiOutlineMinus className='' style={{ position: 'absolute', top: '40%' }} fontSize={'25px'} />
						</div>
						<Link to='/' className='header-logo-container'>
							<div className="header-logo" >
								{/* <img src="/flash-logo.svg" style={{ maxHeight: '100%', width: '100%' }} alt="flash-logo" className="" /> */}
								<FlashLogoSvg className=" hidden sm:flex" />
								<img src="/logo2.png" style={{ width: '50px', height: '50px' }} alt="" className="flex sm:hidden" />
								{/* <div className='text-center leading-4'>
									<h5 className='' style={{ margin: '0!important' }}>FLASH</h5>
									<span style={{ fontSize: '10px' }}>
										<span className='primary-color'>AU</span>DIT
									</span>
								</div> */}
							</div>
						</Link>
					</div>

					<div className={`${isMobile ? 'hamburger-box' : ''} justify`}>
						<div className={`${isMobile ? 'hamburger-cancel-container' : ''} dis-n`}>
							<AiOutlineClose onClick={() => setIsMobile(false)} className='cu-po hamburger-cancel-btn' fontSize={'30px'} color="white-color" />
						</div>
						<ul className='hamburger justify li-none-style'>

							<li className='hamburger-list' onClick={() => topScroll('dead_wallet')}>
								<a >Dead wallet</a>
							</li>
							<li className='hamburger-list' onClick={() => topScroll('flash_recharge')}>
								<a >Flash Recharge / Card</a>
							</li>
							<li className='hamburger-list' onClick={() => topScroll('integrate_vpn')}>
								<a >All services</a>
							</li>
							<li className='hamburger-list' onClick={() => topScroll('flash_transfer')}>
								<a >Flash Transfer</a>
							</li>
							<li className='hamburger-list' onClick={() => topScroll('here_partners')}>
								<a >Partners</a>
							</li>
							<li className='hamburger-list' onClick={() => topScroll('download')}>
								<a >Download</a>
							</li>
							{/* <li className='hamburger-list' onClick={() => setRequestModal(true)}>
								<Link to='/create-wallet'>
									Launch App
								</Link>
							</li> */}
						</ul>
					</div>
					<div onClick={() => setIsMobile(false)} className={`${isMobile ? 'hamburger-bg' : ''} dis-n`}></div>
				</div>
				<div className='launch-app-btn'>
					<div className='justify jc-e flex2'>
						<button style={{ borderRadius: '16px' }} className=' btn primary-bg w10 gap-2 font-bold'>
							<div className='justify gap-2 px-0 sm:px-6'>
								<span className='black-color'>Launch App</span>
							</div>
						</button>
					</div>
					<div className="">
						<img src="/assets/images/flag.png" alt="" className="language" />
					</div>
				</div>
			</div>
			{requestModal &&
				<RequestModal setRequestModal={setRequestModal} />
			}
		</header>
	);
};

export default Header;
