import React from 'react';
import { RiGooglePlayFill, RiStarSFill } from "react-icons/ri";
import { FaTelegramPlane } from "react-icons/fa";

import "./footer.scss";
import { Link } from 'react-router-dom';
const Footer = () => {
    return (
        <>
            <footer className='res-pt6 res-pb6 white-color text-center'>
                <section className='container'>
                    <div className='row'>

                        <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2 mb-6 flex flex-col items-start gap-8">
                            <img src="/assets/images/landing/flash-wallet.png" style={{ width: '100%' }} alt="" className=" max-w-xs" />
                            <img src="/assets/images/landing/buttons/google-pay.png" alt="img" className="w-auto cursor-pointer hover:scale-110" />
                            <img src="/assets/images/landing/buttons/apple.png" alt="img" className="w-auto cursor-pointer hover:scale-110" />
                            <img src="/assets/images/landing/buttons/apk.png" alt="img" className="w-auto cursor-pointer hover:scale-110" />
                        </div>

                        <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2 mb-6 flex flex-col gap-8 text-left">
                            <h5 className='w-full'>Product</h5>
                            <div className="footer-list">
                                <a href="https://www.flash-technologies.org/" target={"_blank"} className="">Flash Technologies</a>
                                <a href="https://flash-transfer.com/" target={"_blank"} className="">Flash Transfer</a>
                                <a href="https://flash-swap.com/" target={"_blank"} className="">Flash Swap</a>
                                <a href="https://flash-staking.com/" target={"_blank"} className="">Flash Staking</a>
                                <a href="https://app.flash-bridge.com/" target={"_blank"} className="">Flash Bridge</a>
                                <a href="https://flash-launch.com/" target={"_blank"} className="">Flash Launchpad</a>
                                <a href="https://flash-audit.com/" target={"_blank"} className="">Flash Audit</a>
                            </div>
                        </div>

                        <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2 mb-6 flex flex-col gap-8 text-left">
                            <h5 className='w-full'>Support</h5>
                            <div className="footer-list">
                                <a href="" className="">Documentation</a>
                                <a href="" className="">Contact us</a>
                            </div>
                        </div>
                        <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-6 dis-only-tablet"></div>
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-2 mb-6 flex flex-col gap-8 text-left">
                            <h5 className='w-full'>Community</h5>
                            <div className="footer-list">
                                <a href="https://twitter.com/Flash_Techno_Of" target={'_blank'} className="">Twitter</a>
                                <a href="https://t.me/flashtokenenglish" target={'_blank'} className="">Telegram</a>
                                <a href="https://www.youtube.com/channel/UCbNApPsnWrYixnri4hwUUyA" className="" target={'_blank'}>Youtube</a>
                                <a href="https://www.facebook.com/flashtechnologiesoff" className="" target={'_blank'}>Facebook</a>
                                <a href="https://www.linkedin.com/company/flash-technologies-off/" className="" target={'_blank'}>Linkedin</a>
                                <a href="" className="" target={'_blank'}>TikTok</a>
                                <a href="https://instagram.com/flash_token_off" className="" target={'_blank'}>Instagram</a>
                                <a href="https://medium.com/@flashtechnologies" target={'_blank'} className="">Medium</a>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-6 flex flex-col gap-8 text-left table-dis-none">
                            <div className="flex items-center justify-start gap-2 w-full">
                                <img src="/assets/images/landing/footer-logo.png" alt="" className="w-full max-w-xs m-auto" />
                            </div>
                        </div>
                    </div>
                </section>
            </footer>
            <div className="footer-copyright">
                <div className=" opacity-30 w-full">Flash Wallet @ 2022</div>
            </div>
        </>
    )
};

export default Footer; 